<template>
  <template v-for="(repeatedIndex, repeatedIndexNumber) in actualIndexValues" :key="repeatedIndex.id">
    <ion-item-divider :class="cssClass">
      <ion-label>
        {{ title }} {{ (repeatable && integerIndex) ? repeatedIndex.value : undefined }}
      </ion-label>
      <div class="select-container">
        <ion-select
          :class="['index-select', (repeatedIndex.value == null) ? 'placeholder' : undefined, getStatusStyle(repeatedIndex.id)]"
          :interfaceOptions="{header: title}"
          v-if="repeatable && hasIndexValues"
          :placeholder="i18n.$t('default_interaction.select')"
          :value="repeatedIndex.value"
          @ionChange="setRepeatedIndex(repeatedIndexNumber, $event.detail.value)">
          <ion-select-option v-for="(indexValue, indexNumber) in indexValueSelection" :key="indexNumber" :value="indexValue.value" :disabled="indexValue.used">{{ indexValue.value }}</ion-select-option>
          <ion-select-option class="index-select-no-selection" :value="null">{{ i18n.$t('forms.index.no_selection') }}</ion-select-option>
        </ion-select>
        <!-- Add custom input to set a custom validity to, that gets checked, before submit. Has no other purpose -->
        <input tabindex="-1" inputmode="none" @focus.stop="$event => cancelFocus($event)" type="text" class="invisible-input custom-validity" :ref="(newRef) => setIndexSelectValidityRef(repeatedIndex.id, newRef)" :name="`${formKey}${formKeySeparator}${repeatedIndex.id}${formKeySeparator}customValidity`" />
      </div>

      <div class="index-buttons" slot="end" v-if="repeatable">
        <!-- Only show delete button, when it is more than one available -->
        <div class="remove-button-container">
          <ExtendableChip
            v-if="hasMultipleUsedIndexValues"
            class="remove-button"
            color="danger"
            :title="i18n.$t('default_interaction.remove_question')"
            extendOnClick
            button
            extendToLeft
            @extendedClick="removeRepeatedIndex(repeatedIndexNumber)"
            :collapseTimeout="5000"
            :brightness="1"
            >
            <template v-slot:permanent>
              <ion-icon :icon="removeOutline" :alt="i18n.$t('default_interaction.remove')"></ion-icon>
            </template>
          </ExtendableChip>
        </div>
        <!-- Only enable the add button, if there are still indizes available (Integer index is always available) --> <!-- TODO Custom index values for string? Especially when no options given? -->
        <ion-button class="add-button" :disabled="!integerIndex && (!hasIndexValuesRemaining || !moreAvailableThanUsed)" color="success" shape="round" fill="solid" @click="addRepeatedIndex()" @keydown="handleSpacebar($event, ()=>$event.target.click())">
          <ion-icon slot="icon-only" :icon="addOutline"></ion-icon> <!-- TODO When adding automatically open select? -->
        </ion-button>
      </div>
    </ion-item-divider>
    <slot :indexId="repeatedIndex.id" :indexValue="repeatedIndex.value"></slot>
  </template>
</template>

<script>
import { IonItemDivider, IonLabel, IonSelect, IonSelectOption, IonIcon, IonButton } from '@ionic/vue';
import { computed, ref, watch } from 'vue';

import { addOutline, removeOutline } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { handleSpacebar, cancelFocus } from '@/utils/interaction';

import { createSortFunctionByArray } from '@/utils/algorithms';

import ExtendableChip from '@/components/ExtendableChip.vue';

import _ from 'lodash';

export default  {
  name: 'RepeatedDividerSeleciton',
  components: { IonItemDivider, IonLabel, IonSelect, IonSelectOption, IonIcon, IonButton, ExtendableChip },
  props: {
    title: String,
    cssClass: String,
    repeatable: {
      type: Boolean,
      default: false
    },
    integerIndex: {
      type: Boolean,
      default: false
    },
    formKey: {
      type: String,
      default: 'no_key'
    },
    formKeySeparator: {
      type: String,
      default: '->'
    },
    indexValues: Array,
    presetUsedIndexValues: Array, //Should have ID set to their value already
    invalidIfEmpty: Array, //Array of all indizes that are treated as invalid if an index value is not set
  },
  emits: ['removedIndex', 'update:invalid'],
  setup(props, { emit }){
    const i18n = useI18n();

    //Internal count to have a unique identifier while editing
    const internalIndexCount = ref(0);

    //Use abstraction to either return the already set ones or empty array //TODO Test on edit! Especially with integer index
    const presetUsedIndexAbstraction = computed(() => {
      if (!Array.isArray(props.presetUsedIndexValues) || props.presetUsedIndexValues.length < 1) return [];
      return props.presetUsedIndexValues;
    })

    const usedIndexValues = ref([]);

    //Set id and value to null if it is not repeatable so it gets rendered once but not used otherwise
    const actualIndexValues = computed(() => {
      if (props.repeatable === true) {
        return usedIndexValues.value;
      } else {
        return [{ id: null, value: null}];
      }
    });

    const indexSelectValidityRefs = ref({});

    const setIndexSelectValidityRef = function(id, reference) {
      indexSelectValidityRefs.value[id] = reference;
    }

    watch([presetUsedIndexAbstraction, () => props.repeatable], (newValues, oldValues) => {
      //Only update preset, when something changes!
      if (_.isEqual(newValues, oldValues)) return;

      let [newPresetUsedIndexValues, currentRepeatableStatus] = newValues;
      let newMappedIndexValues;

      //Do only set presets, if it is repeatable
      if (currentRepeatableStatus) {
        //Map to internal format with unique index id
        newMappedIndexValues = _.map(newPresetUsedIndexValues, (indexValue, indexNumber) => {
          let indexValueProperty = indexValue;
          let returnValue;
          if (indexValue != null && indexValue.id != null) { //Already valid format, return if it is not integer index. For integer index we can't reuse id to avoid duplicates and need to continue with mapping the value
            if (props.integerIndex) indexValueProperty = parseInt(indexValue.value) || (indexNumber + 1); //Try to convert to integer or use the current index if not possible
            else return { ...indexValue, preset: indexValue.value };
          }
          //Otherwise convert and give unique id
          returnValue = { id: internalIndexCount.value, value: indexValueProperty, preset: indexValueProperty };
          internalIndexCount.value++;
          return returnValue;
        });
      } else { //Otherwise don't add any presets
        newMappedIndexValues = [];
      }

      //If the given index values that have been used change, add them to all existing ones (without replacing them) with non-strict equality to match strings and numbers too
      let newUsedIndexValues = _.unionWith(usedIndexValues.value, newMappedIndexValues, (a, b) => a.value == b.value); //Compare index value to catch existing values from the preset with different IDs

      //If it is integer_index, set the numbers all correctly again in the order
      if (props.integerIndex) {
        newUsedIndexValues = _.map(newUsedIndexValues, (indexValue, indexNumber) => {
          return {
            ...indexValue,
            value: (indexNumber + 1)
          }
        });
      }

      //If we do not have any set yet, add an empty one. As soon as one has been set, no new empty ones get added automatically!
      if (newUsedIndexValues.length < 1) {
        newUsedIndexValues.push({ id: internalIndexCount.value, value: ((props.integerIndex) ? 1 : null) })
        internalIndexCount.value++;
      }

      usedIndexValues.value = newUsedIndexValues;
      //TODO Maybe if nothing is filled out (not invalid when empty) and the index is null and new indizes are set here, remove the null one!
    }, { immediate: true });

    //All the Index Values that have not been selected yet
    const remainingIndexValues = computed(() => {
      if (!hasIndexValues.value) return [];
      //If we have available values return all that have not been used yet with non-strict equality to match strings and numbers too
      return _.differenceWith(props.indexValues, _.map(usedIndexValues.value, 'value'), (a, b) => a == b);
    });

    //If Index Values have been supplied
    const hasIndexValues = computed(() => {
      return (Array.isArray(props.indexValues) && props.indexValues.length >= 1);
    });

    const indexValueSelection = computed(() => {
      if (hasIndexValues.value) {
        //Merge used and available index values together, using map first to add a boolean if it is used or not and then union together using the used ones first (non-strict equality to match strings and numbers too)
        return _.filter(
          _.unionWith(
            _.map(usedIndexValues.value, usedIndexValue => ({value: usedIndexValue.value, used: true})),
            _.map(props.indexValues, availableIndexValue => ({value: availableIndexValue, used: false}))
          , (a, b) => a.value == b.value),
        (selectionItem) => selectionItem.value != null).sort(createSortFunctionByArray(props.indexValues, 'value')); //Resulting array sorted by occurence in given Index Values (If not present sorted to the end)
      }
      return [];
    });

    //If Index Values have been selected by the user
    const hasUsedIndexValues = computed(() => {
      return (Array.isArray(usedIndexValues.value) && usedIndexValues.value.length >= 1);
    });
    //And if more than one has been selected
    const hasMultipleUsedIndexValues = computed(() => {
      return (hasUsedIndexValues.value && usedIndexValues.value.length > 1);
    });

    //If there are any Index Values left that have not been selected yet
    const hasIndexValuesRemaining = computed(() => {
      return (Array.isArray(remainingIndexValues.value) && remainingIndexValues.value.length >= 1);
    });

    const moreAvailableThanUsed = computed(() => {
      if (!(Array.isArray(props.indexValues) )) return false;
      if (!(Array.isArray(usedIndexValues.value) )) return false;
      return props.indexValues.length > usedIndexValues.value.length;
    });

    const addRepeatedIndex = function() {
      //Extend it by one empty one (or the count of the current index + 1 to get the next number in the row) and re set it to call watchers
      usedIndexValues.value = _.concat(usedIndexValues.value, [{ id: internalIndexCount.value, value: (props.integerIndex) ? (usedIndexValues.value.length + 1) : null }]);
      internalIndexCount.value++;
    }

    const setRepeatedIndex = function(indexNumber, newIndexValue) {
      //Sanitize to catch being set as null!
      let sanitizedNewIndexValue = newIndexValue;
      if (newIndexValue == null || (_.isString(newIndexValue) && !newIndexValue.length)) sanitizedNewIndexValue = null;
      //Change index value at the given array index number on a copy
      let oldIndex = _.cloneDeep(usedIndexValues.value);
      oldIndex[indexNumber].value = sanitizedNewIndexValue;
      //Then re set the array to call watchers
      usedIndexValues.value = oldIndex;
    }

    const removeRepeatedIndex = function(indexNumber) {
      //Remove at the array index number on a copy
      let oldIndex = [...(usedIndexValues.value)];
      _.pullAt(oldIndex, indexNumber);

      //If it is integer_index, set the numbers all correctly again in the order
      if (props.integerIndex) {
        oldIndex = _.map(oldIndex, (indexValue, indexNumber) => {
          return {
            ...indexValue,
            value: (indexNumber + 1)
          }
        });
      }

      //And set it again to call watchers
      usedIndexValues.value = oldIndex;
    }

    const invalidIndexIDs = computed(() => {
      if (!(props.repeatable)) return []; //No indizes are invalid if it is not repeatable
      let emptyIndexValues = _.pickBy(usedIndexValues.value, (indexValue) => indexValue.value == null);
      //Intersection of empty ones and invalidIfEmpty gives the array of invalid IDs
      return _.intersectionWith(_.map(emptyIndexValues, 'id'), props.invalidIfEmpty, (a, b) => a == b);
    });

    const isIndexIDInvalid = computed(() => {
      let currentInvalidIndexIDs = invalidIndexIDs.value;
      return function(indexId) {
        return _.some(currentInvalidIndexIDs, (invalidId) => invalidId == indexId);
      }
    });

    const getStatusStyle = computed(() => {
      let invalidCheck = isIndexIDInvalid.value;
      let currentIndexValues = usedIndexValues.value;

      return function(indexId) {
        let currentIndex = _.find(currentIndexValues, (indexValue) => indexValue.id == indexId );

        //Invalidity is always the state that overrides all others
        if (invalidCheck(indexId)) {
          return 'invalid';
        }
        //If it is not empty and different from preset, it is considered modified
        else if (currentIndex != null && currentIndex.value != null && currentIndex.value != currentIndex.preset) {
          return 'modified';
        }
        //If preset is valid and it is not modified from preset, return preset
        else if (currentIndex != null && currentIndex.preset != null && currentIndex.value == currentIndex.preset) {
          return 'preset';
        } 
        //If it is valid, is not different from the preset and the preset was undefined, the field is considered factory state and thus has no specific status
        else {
          return '';
        }
      }
    });

    //Check for changes concering the validity and set the custom validity accordingly
    watch([indexSelectValidityRefs, invalidIndexIDs], ([newSelectValidityRefs, newInvalidIndexIDs]) => {
      for (let [indexId, selectValidityRef] of Object.entries(newSelectValidityRefs)) {
        //Only continue setting the validity, if the select exists
        if (selectValidityRef != null) {
          //If it should be invalid if empty and it is considered empty (null), then set the custom invalidity
          if (indexId != null && _.some(newInvalidIndexIDs, (invalidId) => invalidId == indexId)) {
            selectValidityRef.setCustomValidity(i18n.$t('default_interaction.input_required') || 'Input required'); //Use translated input required or fallback string
            emit('update:invalid', indexId, true);
          } else { //Otherwise reset it
            selectValidityRef.setCustomValidity('');
            emit('update:invalid', indexId, false);
          }
        }
      }
    }, { immediate: true });

    watch(usedIndexValues, (newIndexValues, oldIndexValues) => {
      let removedIndizes = _.differenceWith(oldIndexValues, newIndexValues, (a, b) => a.id == b.id);
      //Give back all the ones that have been removed on a change. Old ones without the new ones results in all that are not included anymore. Non-strict equality to match strings and numbers too
      if (removedIndizes.length >= 1) {
        emit('removedIndex', removedIndizes);
      }
    });

    return {
      i18n, 
      handleSpacebar,
      cancelFocus,
      usedIndexValues,
      actualIndexValues,
      remainingIndexValues,
      hasIndexValues,
      getStatusStyle,
      indexValueSelection,
      setIndexSelectValidityRef,
      hasUsedIndexValues,
      hasMultipleUsedIndexValues,
      hasIndexValuesRemaining,
      moreAvailableThanUsed,
      addRepeatedIndex,
      setRepeatedIndex,
      removeRepeatedIndex,
      addOutline,
      removeOutline
    };
  }
}
</script>

<style>
.index-select-no-selection {
  font-style: italic;
}
</style>

<style scoped>
.index-select {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-inline: 10px;
}

.index-select.placeholder {
  color: var(--placeholder-color);
  opacity: var(--placeholder-opacity);
}

.index-select.invalid {
  color: var(--ion-color-danger);
  opacity: 1;
}

.index-select.modified {
  color: var(--ion-color-success-shade);
}

.index-select.preset {
  color: var(--ion-color-primary-text);
}

.index-buttons {
  --button-border-size: 2px;
  --button-size: 1.8em;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-inline-start: 10px;
}

.remove-button-container {
  position: relative;
  width: var(--button-size);
  height: var(--button-size);
}

.index-buttons .remove-button {
  position: absolute;
  --custom-size: var(--button-size)!important;
  margin: 0px;
  right: 0px;
  z-index: 1000;
}

.index-buttons .add-button {
  margin-inline-start: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  --padding-start: 2px;
  --padding-end: 2px;
  width: calc(var(--button-size) + (var(--button-border-size) * 2));
  height: calc(var(--button-size) + (var(--button-border-size) * 2));
}

.select-container {
  position: relative;
}

.invisible-input {
  /* Avoid reflow of other elements */
  position: absolute;
  /* Show any invalidity popups in the center of the element. Moved down slightly to better point at the missing input. */
  left: 50%;
  bottom: 25%;
  /* Hide any possibly visible parts of the input */
  background: var(--ion-background-color, white);
  border: none;
  opacity: 0;
  z-index: -10;
  /* Prevent user clicks on element, that would open the dialog twice */
  pointer-events: none;
  /* Make the element as small as possible, so it still can show the validity popup */
  width: 1px;
  height: 1px;
}
</style>
